.Watch-Automation {
    padding: 10px;
}

.Watch-Automation-filter {
    padding: 10px 0px;
}

.filter-list {
    display: inline;
    padding: 10px;
}